import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderTopBar from '../common/header/HeaderTopBar';
import AboutFour from '../elements/about/AboutFour';
import AdvanceTabTwo from '../elements/advancetab/AdvanceTabTwo';
import CalltoActionTwo from '../elements/calltoaction/CalltoActionTwo';
import CalltoActionTwo_2 from '../elements/calltoaction/CalltoActionTwo_2';
import CalltoActionTwo_3 from '../elements/calltoaction/CalltoActionTwo_3';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import TestimonialOne from "../elements/testimonial/TestimonialOne";



const Home = () => {
    return (
        <>
            <SEO title="Home" />
            <main className="page-wrapper">
                {/* <HeaderTopBar /> */}
                <HeaderTopNews />
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-white" />

                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionTwo />
                    </div>
                </div>
                {/* End Elements Area  */}
                
                {/* Start Elements Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--10">
                                <SectionTitle
                                    textAlign = "text-center"
                                     radiusRounded = ""
                                    subtitle = ""
                                    title = "Testimonials"
                                />
                            </div>
                    </div>
                        <TestimonialOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one" />
                    </div>
                </div>
                {/* End Elements Area  */}
                {/* <AboutFour image="./images/about/contact-image.jpg" /> */}
                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionTwo_2 />
                    </div>
                </div>
                {/* End Elements Area  */}
                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Service Delivery"
                                    title = "How Trinity Works With Your Organization"
                                    description = ""
                                />
                            </div>
                        </div>
                        <AdvanceTabTwo />
                    </div>
                </div>
                {/* End Elements Area  */}
                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionTwo_3 />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* <Separator /> */}

                <FooterTwo />
                <Copyright />
            </main>

        </>
    )
}

export default Home;
