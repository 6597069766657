import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const testimonialData = [
    {
        image: 'testimonial-01',
        name: 'Wayne Lamon',
        designation: 'President',
        location: 'Lamon+Stuart+Michael', 
        description: 'Trinity managed a team of 10 to successfully recruit, in a compressed timeframe, scarce individuals for a high tech client. They developed a staffing model & recruitment strategies that got results."',
       
    },
    {
        image: 'testimonial-02',
        name: 'Mary Deschamps',
        designation: 'Vice President HR',
        location: 'Cotton Ginny/Tabi', 
        description: 'Trinity provided us with excellent support, allowing us to take ownership and prepared to handle the technical issues at hand. You made us feel really comfortable as we tackled new ground.',
      
    },
    {
        image: 'testimonial-03',
        name: 'Shaheen Ahmad, P.Eng.',
        designation: 'President',
        location: 'Shaheen&Peaker Limited', 
        description: "I found Marylka's work to be exceptional. She is fully versed in all aspects of human resources management, and ensure that we fulfilled all legislative requirements.",
    },
]


const TestimonialOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {testimonialData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-box-card ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/testimonial/${data.image}.jpg`} alt="Trinity" />
                                </figure>
                                <figcaption className="content">
                                    <p className="description">{data.description}</p>
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TestimonialOne;
