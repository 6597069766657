import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceTwo from "../elements/service/ServiceTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";

const ServiceDelivery = () => {
    return (
        <>
            <SEO title="Service Delivery" />
            <main className="page-wrapper">
                <HeaderTopNews />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-white" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="6" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/headerservices.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        {/* <h3 className="rn-sub-badge"><span className="theme-gradient">Our Company's About Details.</span></h3> */}
                                    </div>
                                    <h1 className="title display-one">We work closely with clients to help identify and clarify their requirements</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">We provide exceptional service delivery to our clients </h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Trinity has completed projects across the full spectrum of HR functional areas to meet specialized requirements that are time based and have defined deliverables.  We work closely with clients to help identify and clarify their requirements, and agree on priorities which approach is best suited for delivery of services.</p>
                            </div>
                <br />
                <br />            
                            
                        </div>
                    </div>
                </div>
                <div>
                <Separator />
                <br />    
                <ServiceTwo></ServiceTwo>
                <br />
                <Separator />  
                </div>
                {/* End Service Area  */}



  



                
                <Separator />


                <FooterTwo />
            </main>
        </>
    )
}

export default ServiceDelivery;
