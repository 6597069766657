import React from 'react';
import { FiArrowRight } from "react-icons/fi";

const callToActionData = {
    title: "Trinity Associates",
    subtitle: "Providing Master Strategies in Human Capital Management to Progressive Organizations ",    
}

const CalltoActionTwo = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-1 bg_image bg_image_fixed"  style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/header.jpg)`}} data-black-overlay="5">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title mb--5">{callToActionData.title}</h2>
                                <p>Providing Master Strategies in Human Capital Management to Progressive Organizations </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionTwo;