import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderTopBar from '../common/header/HeaderTopBar';
import AboutFour from '../elements/about/AboutFour';
import AdvanceTabTwo from '../elements/advancetab/AdvanceTabTwo';
import CalltoActionTwo from '../elements/calltoaction/CalltoActionTwo';
import CalltoActionTwo_4 from '../elements/calltoaction/CalltoActionTwo_4';
import CalltoActionTwo_2 from '../elements/calltoaction/CalltoActionTwo_2';
import CalltoActionTwo_3 from '../elements/calltoaction/CalltoActionTwo_3';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import TestimonialOne from "../elements/testimonial/TestimonialOne";



const NFP = () => {
    return (
        <>
            <SEO title="NFP Sector" />
            <main className="page-wrapper">
                {/* <HeaderTopBar /> */}
                <HeaderTopNews />
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-white" />

                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionTwo_4 />
                    </div>
                </div>
                {/* End Elements Area  */}
                


                <FooterTwo />
                <Copyright />
            </main>

        </>
    )
}

export default NFP;
