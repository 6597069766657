import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'Company Story',
        description: 'As a "boutique" firm, Trinity provides services to clients more responsively, with greater personalization, and with the president of the company behind the helm of every major decision.  Trinity’s low overhead enables all clients the ability to benefit from “large firm” much more most cost-effectively.'
    },
    {
        icon: <FiCast />,
        title: 'Mission & Values',
        description: 'Mission: To maximize the value of our clients’ most valuable resource, their employees. Company Values: <strong>Integrity</strong>  in our Client relationships,<strong>Quality</strong> in the service we provide,<strong>Results</strong> that fill your needs and requirements, <strong>Commitment</strong> to excellence in all that we do, <strong>Responsibility</strong> in the achievement of our clients’ goals. '
    },
    {
        icon: <FiMap />,
        title: 'Our Clients',
        description: 'Trinity Associates assists small to large sized companies from a wide industry base. Whether your company is privately held, or publicly traded, our consulting services can breathe new life into the everyday functions of HR management – giving you greater efficiency, productivity, and opportunity for increased success.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;