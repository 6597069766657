import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="/">Home</Link>
            </li>
            
            <li><Link to="/about-us">About</Link></li>

            <li className="has-droupdown"><Link to="strategies">Lead Strategies</Link>
                <ul className="submenu">
                    <li><Link to="#">Introduction</Link></li>
                    <li><Link to="#">Recruiting & Retaining</Link></li>
                    <li><Link to="#">Leadership & Management</Link></li>
                    <li><Link to="#">HR Management</Link></li>
                </ul>
            </li>

            <li className="has-droupdown"><Link to="ServiceDelivery">Services</Link>
                <ul className="submenu">
                    <li><Link to="#">Aligning Human Capital</Link></li>
                    <li><Link to="#">HR Audits</Link></li>
                    <li><Link to="#">Legislative Compliance</Link></li>
                    <li><Link to="#">Compensation Design</Link></li>
                    <li><Link to="#">Performance Management</Link></li>
                    <li><Link to="#">Leadership Coaching</Link></li>
                    <li><Link to="#">Policy & Procedures</Link></li>
                    <li><Link to="#">Employee Tools & Services</Link></li>
                    <li><Link to="#">Labor Relations</Link></li>
                    <li><Link to="#">Organization "RightSizing"</Link></li>
                </ul>
            </li>

            <li className="has-droupdown"><Link to="NFP">NFP Sector</Link>
            </li>

            <li className="has-droupdown"><Link to="/contact">Contact</Link></li>
        </ul>
    )
}
export default Nav;
