import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                <HeaderTopNews />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-white" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="6" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/headerstrategies.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        {/* <h3 className="rn-sub-badge"><span className="theme-gradient">Our Company's About Details.</span></h3> */}
                                    </div>
                                    <h1 className="title display-one">Master The Art Of Human Capital Management</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Our Master Strategies are custom tailored.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Trinity's proprietary Master Strategies' programs have been designed to address today's most crucial human resource management challenges and objectives</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator />


                <AboutFour image="./images/about/contact-image.jpg" />


                <Separator />



                <FooterTwo />
            </main>
        </>
    )
}

export default AboutUs;
