import React from 'react';
import { FiArrowRight } from "react-icons/fi";

const callToActionData = {
    title: "Assisting Organizations That Lend a Helping Hand ",
    subtitle: "Finest choice for your home & office",
    btnText: "Learn More",
}

const CalltoActionTwo = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed"  style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/headernfp.jpg)`}} data-black-overlay="5">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title mb--0">{callToActionData.title}</h2>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionTwo;